<template>
    <div>
        <div class="d-flex flex-nowrap pb-2 mb-2" style="overflow-y: auto">
            <div v-for="item in tabs" :key="item.status" style="min-width: 235px; width: 20%" class="ma-1">
                <v-btn block :color="filters.status == item.status ? 'primary' : 'defalut'"
                    @click="onTabChange(item.status)">
                    <img :src="item.img" alt="" style="width: 25px; margin-right: 15px" />
                    {{ item.text }}
                    <v-chip v-if="item.status < 5" class="ma-2" small>
                        {{ counts[`count_status_${item.status}`] }}
                    </v-chip>
                </v-btn>
            </div>
        </div>

        <v-simple-table fixed-header height="calc(100vh - 216px)" class="table-padding-2">
            <template v-slot:default>
                <thead class="v-data-table-header">
                    <tr>
                        <th role="columnheader">
                            <DateRangeFilter :label="$t('labels.create_time')" :placeholder="$t('labels.create_time')"
                                name="created_at" sort-name="created_at" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader">
                            <DateRangeFilter :label="$t('labels.last_update')" :placeholder="$t('labels.last_update')"
                                name="last_action_at" sort-name="last_action_at" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader">
                            <InputFilter :label="$t('labels.tracking')" :placeholder="$t('labels.tracking')"
                                name="tracking_id" sort-name="tracking_id" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader">
                            <SelectPos :label="$t('labels.pos')" :placeholder="$t('labels.pos')"
                                name="id_pos" sort-name="pos_code" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader">
                            <SelectFilter :label="$t('labels.find_goods_customer_status')"
                                :placeholder="$t('labels.find_goods_customer_status')" name="customer_status"
                                sort-name="customer_status" has-sort :sorting="filters.sort_by"
                                :options="customerStatusOptions" @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader">
                            <SelectEmployee :label="$t('labels.pickup_employee')"
                                :placeholder="$t('labels.pickup_employee')" name="id_employee_pickup"
                                sort-name="employee_pickup_name" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader">
                            <SelectEmployee :label="$t('labels.packing_employee')"
                                :placeholder="$t('labels.packing_employee')" name="id_employee_packing"
                                sort-name="employee_packing_name" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th>
                        <th role="columnheader">
                            <InputFilter :label="$t('labels.basket')" :placeholder="$t('labels.basket')" name="basket_code"
                                sort-name="basket_code" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                                @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" class="text-center" style="min-width: 100px">
                            <InputFilter :label="$t('labels.sku')" :placeholder="$t('labels.sku')" name="sku"
                                sort-name="sku" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                                @onSort="onSortChange" />
                        </th>
                        <th role="columnheader" class="text-center">{{ $t('labels.missing_quantity') }}</th>
                        <th role="columnheader" class="text-center">{{ $t('labels.finded_quantity') }}</th>
                        <th role="columnheader" class="text-center">{{ $t('labels.remaining_quantity') }}</th>
                        <th role="columnheader" class="text-center" style="min-width: 100px">N.viên xử lý</th>
                        <th role="columnheader" class="text-center"></th>
                    </tr>
                </thead>

                <tbody v-for="(item, index) in items" :key="`i_${index}_${item.id}`">
                    <tr class="text-center" v-for="(child, ck) in item.children" :key="`c_${ck}_${item.sku}`">
                        <td v-if="ck === 0" :rowspan="item.children.length">
                            {{ formatDateTime(item.created_at) }}
                        </td>
                        <td v-if="ck === 0" :rowspan="item.children.length">
                            {{ item.last_action_at ? formatDateTime(item.last_action_at) : '' }}
                        </td>
                        <td v-if="ck === 0" :rowspan="item.children.length">
                            <OrderTracking :tracking-id="item.tracking_id" />
                            <div v-if="item.request_cancel" class="mt-1">
                                <v-chip color="error" small>{{ $t('labels.cancelled') }}</v-chip>
                            </div>
                        </td>
                        <td v-if="ck === 0" :rowspan="item.children.length">
                            {{ item.warehouse_code }}
                        </td>
                        <td v-if="ck === 0" :rowspan="item.children.length">
                            {{ $t(`labels.find_goods_customer_status_${item.customer_status}`) }}
                        </td>
                        <td v-if="ck === 0" :rowspan="item.children.length">
                            {{ item.employee_pickup_name }}
                        </td>
                        <td v-if="ck === 0" :rowspan="item.children.length">
                            {{ item.employee_packing_name }}
                        </td>
                        <td v-if="ck === 0" :rowspan="item.children.length">
                            {{ item.basket_code }}
                        </td>
                        <td>
                            {{ child.sku }}
                        </td>
                        <td>{{ child.missing_quantity }}</td>
                        <td>
                            <span class="text-decoration-underline cursor-pointer primary--text"
                                @click="showDialogHistory(child)">
                                {{ child.finded_quantity }}
                            </span>
                        </td>
                        <td>
                            <span class="text-decoration-underline cursor-pointer error--text"
                                @click="showDialogDetail(child)">
                                {{ child.remaining_quantity }}
                            </span>
                        </td>
                        <td>{{ child.employee_receipt }}</td>
                        <td>
                            <v-btn color="error" x-small @click="showDialogLostItem(item)"
                                v-if="([2, 3].includes(item.customer_status) || item.request_cancel === 1) && !item.stamp_status">
                                {{ $t('labels.lost_item') }}
                            </v-btn>
                            <v-btn color="error" x-small disabled
                                v-else-if="item.status === 3 && item.customer_status === 0 && !item.stamp_status && !item.request_cancel">
                                {{ $t('labels.lost_item') }}
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-row class="pt-3">
            <v-col cols="1"></v-col>
            <v-col cols="10">
                <v-pagination v-model="page" :length="totalPage" :total-visible="6"></v-pagination>
            </v-col>
            <v-col cols="1"></v-col>
        </v-row>

        <v-dialog v-model="dialogHistory" persistent max-width="600px">
            <FindGoodsHistory v-if="dialogHistory && itemDetail && itemDetail.id" :item="itemDetail"
                @closeDialog="closeDialogHistory" />
        </v-dialog>

        <v-dialog v-model="dialogDetail" persistent max-width="400px">
            <FindGoodsDetail v-if="dialogDetail && itemDetail && itemDetail.id" :item="itemDetail"
                @closeDialog="closeDialogDetail" />
        </v-dialog>

        <v-dialog v-model="dialogLostItem" persistent max-width="600px">
            <FindGoodsLostItem v-if="dialogLostItem && itemDetail && itemDetail.id_goods_issue_detail" :item="itemDetail"
                @closeDialog="closeDialogLostItem" />
        </v-dialog>
    </div>
</template>
  
<script>
import { httpClient } from '@/libs/http'
import { FIND_GOODS_CUSTOMER_STATUS } from '@/libs/const'
import { debounce } from 'lodash/function'
export default {
    name: "FindManager",
    components: {
        SelectPos: () => import('@/components/table/SelectPos'),
        DateRangeFilter: () => import('@/components/table/DateRangeFilter'),
        InputFilter: () => import('@/components/table/InputFilter'),
        SelectFilter: () => import('@/components/table/SelectFilter'),
        SelectEmployee: () => import('@/components/table/SelectEmployee'),
        FindGoodsHistory: () => import('@/components/pos_goods/FindGoodsHistory'),
        FindGoodsDetail: () => import('@/components/pos_goods/FindGoodsDetail'),
        FindGoodsLostItem: () => import('@/components/pos_goods/FindGoodsLostItem'),
        OrderTracking: () => import('@/components/common/OrderTracking'),
    },
    data: () => ({
        tabs: [
            {
                text: 'Chưa tìm',
                status: 1,
                img: require('@/assets/internal_requests/1_Cho_xu_Ly.png')
            },
            {
                text: 'Tìm bổ sung',
                status: 2,
                img: require('@/assets/internal_requests/2_Dang_xu_ly.png')
            },
            {
                text: 'Không thấy',
                status: 3,
                img: require('@/assets/internal_requests/3_Cho_xac_nhan.png')
            },
            {
                text: 'Đã thấy',
                status: 4,
                img: require('@/assets/internal_requests/4_Da_hoan_thanh.png')
            },
            {
                text: 'Đóng yêu cầu',
                status: 5,
                img: require('@/assets/internal_requests/5_ngung_xu_ly.png')
            }
        ],
        page: 1,
        totalPage: 1,
        counts: {},
        items: [],
        filters: {
            status: 3,
        },
        isLoading: false,
        dialogDetail: false,
        dialogHistory: false,
        dialogLostItem: false,
        dialogEmployee: false,
        itemDetail: {},
    }),
    computed: {
        customerStatusOptions() {
            return [...FIND_GOODS_CUSTOMER_STATUS].map(value => ({
                value,
                text: this.$t(`labels.find_goods_customer_status_${value}`)
            }))
        }
    },
    watch: {
        page() {
            this.getList()
        },
        filters: {
            handler() {
                this.page = 1
                this.getList()
            },
            deep: true
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        onFilterChange(filter) {
            this.filters = { ...this.filters, [filter.name]: filter.value }
        },
        onSortChange(sort) {
            this.filters = { ...this.filters, ...sort }
        },
        onTabChange(tab) {
            this.filters = { ...this.filters, status: tab }
        },
        getList: debounce(function () {
            httpClient.post('/find-goods-manager-list', { ...this.filters, page: this.page }).then(({ data }) => {
                this.counts = { ...data.counts }
                this.totalPage = data.totalPage
                this.items = [...data.rows]
            })
        }, 1000),
        showDialogDetail(item) {
            this.itemDetail = { ...item }
            this.dialogDetail = true
        },
        closeDialogDetail() {
            this.itemDetail = {}
            this.dialogDetail = false
        },
        showDialogHistory(item) {
            this.itemDetail = { ...item }
            this.dialogHistory = true
        },
        closeDialogHistory() {
            this.itemDetail = {}
            this.dialogHistory = false
        },
        showDialogLostItem(item) {
            this.itemDetail = { ...item }
            this.dialogLostItem = true
        },
        closeDialogLostItem() {
            this.itemDetail = {}
            this.dialogLostItem = false
        },
    }
}
</script>
  
<style scoped></style>
  